<template>
    <div id="whole-history-page">
        <div id="output-text">{{ this.outputText }}</div>
        <div id="masterminds-history-turns-wrapper">
            <div v-for="(turn, idx) in this.passedTurns" :key="idx" id="entire-turn-element">
                <div id="masterminds-history-turn">
                    <span v-for="n in turn.Word.length" :key="n" id="masterminds-history-letter-wrapper">
                        <span v-if="this.letterInfo[turn.Word[n - 1]] == 0" id="masterminds-history-letter" style="background-color: white;"><div>{{ turn.Word[n - 1].toUpperCase() }}</div></span>
                        <span v-else-if="this.letterInfo[turn.Word[n - 1]] == 1" id="masterminds-history-letter" style="background-color: red;"><div>{{ turn.Word[n - 1].toUpperCase() }}</div></span>
                        <span v-else-if="this.letterInfo[turn.Word[n - 1]] == 2" id="masterminds-history-letter" style="background-color: yellow;"><div>{{ turn.Word[n - 1].toUpperCase() }}</div></span>
                        <span v-else-if="this.letterInfo[turn.Word[n - 1]] == 3" id="masterminds-history-letter" style="background-color: green;"><div>{{ turn.Word[n - 1].toUpperCase() }}</div></span>
                    </span>
                </div>
                <div id="masterminds-history-word-result-wrapper"><div id="masterminds-history-word-result"><div>Result:</div>{{ turn.Result }}</div></div>
            </div>

            <br>
        </div>

        <div id="masterminds-keyboard-element-wrapper">
            <div id="masterminds-keyboard-element">
                <MastermindsKeyboard :gameStatus="this.gameStatus" @colorUpdate="updateLetterInfo"/>
            </div>
        </div>
    </div>
</template>

<script>
// import router from "@/router"
import axios from "axios"
import { store } from '@/stores/store';

import MastermindsKeyboard from './MastermindsKeyboard.vue'
export default {
    name: "MastermindsGuessHistory",

    components: {
        MastermindsKeyboard,
    },

    data() {
        return {
            outputText: "",
            gameStatus: "",
            letterInfo: {},
            passedTurns: [],
        }
    },

    beforeMount() {
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;

        axios.get("https://smada.com/api/mastermind/game/" + this.$route.params.id)
        .then(response => {
            this.updateLetterInfo(JSON.parse(response.data.Letters))
            this.passedTurns = response.data.Turns;
            this.gameStatus = response.data.Status;
        })
        .catch(error => {
            console.log("An error occured: ", error);
        })
    },

    methods: {
        updateLetterInfo(letters) {
            this.letterInfo = letters;
        },

        handleSpecialKeyValues(e) {
            let value;
            if(e.key)
            {
                value = e.key
            }
            else if(e.target)
            {
                value = e.target.id;
            }
            else
            {
                value = e;
            }
            if(value == 'Backspace')
            {
                this.$parent.currentGuessValue = this.$parent.currentGuessValue.slice(0, -1);
            }
            else if(value == 'Enter')
            {
                this.outputText = "";
                axios.post("https://smada.com/api/mastermind/game/" + this.$route.params.id, { MyWord:  this.$parent.currentGuessValue })
                .then(response => {
                    if(response.data.Result == undefined)
                    {
                        this.outputText = "You got the word!  You won!";
                        console.log("The game is over");
                        this.$parent.yourTurn = false;
                        this.$parent.currentGuessValue = "";
                        return
                    }
                    else
                    {
                        this.passedTurns.push(response.data);
                        this.outputText = "Your result for " + this.passedTurns[this.passedTurns.length - 1].Word.toUpperCase() + " is " + response.data.Result;
                    }
                    this.$parent.currentGuessValue = "";
                    this.$parent.yourTurn = false;
                    this.$parent.checkTurnUpdate();
                })
                .catch(error => {
                    console.log("An error occured: ", error);
                    if (error.response)
                    {
                        this.outputText = error.response.data;
                    }
                    else
                    {
                        this.outputText = "Something went wrong";
                    }
                })
            }
        },
    },
}
</script>

<style scoped>
#whole-history-page {
    background-color: lightgrey;
    width: 100vw;
    display: grid;
    grid-template-rows: 0.3fr 2fr;
    margin-bottom: 2px;
    margin-top: 2px;
}

#output-text {
    height: 40px;
    color: red;
    margin-top: 10px;
}

#entire-turn-element {
    display: grid;
    grid-template-columns: 8.5fr 1.5fr;
}

#masterminds-history-turns-wrapper {
    height: 20vh;
    width: 99%;
    overflow: scroll;
}

#masterminds-history-turn {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1.5em;
    background-color: grey;
    margin-bottom: 3vh;
    width: 100%;
    height: auto;
    margin-left: 1vw;
    margin-right: 5vw;
}

#masterminds-history-word-result-wrapper {
    margin-left: auto;
    width: 100%;
    height: 40px;
    background-color: grey;
    display: flex;
    justify-content: center;
    align-items: center;
}

#masterminds-your-turn-element {
    margin-top: 20px;
}

#masterminds-history-letter {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 5vw;
    height: 40px;
    margin-left: 5px;
}

#masterminds-keyboard-element-wrapper {
    display: flex;
    justify-content: center;
    width: 100vw;
    position: absolute;
    bottom: 0px;
}
</style>