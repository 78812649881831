<template>
    <div>
        <div class="checkbox-wrapper">
            <input id="opponent-now-checkbox" type="checkbox" name="opponent-preference" v-model="opponentNow"/>
            <label class="checkbox-label" for="opponent-now-checkbox">Choose Opponent</label>
        </div>

        <!--Player list-->
        <div v-if="this.opponentNow == true">
            <div v-for="(player, idx) in this.playerList" :key="idx" >
                <div class="masterminds-player-list-name-wrapper-wrapper">
                    <div
                    class="masterminds-player-list-name-wrapper" :id="player.ID"
                    @click="() => {
                        this.oldOpponentId = this.opponentId;
                        handleOpponentChoice(idx);
                    }"
                    >
                        <div class="masterminds-player-list-name">
                            {{ player.Name }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { store } from '@/stores/store';

export default {
    name: "OpponentSelection",

    data() {
        return {
            playerList: [],
            opponentNow: true,
            opponentId: "",
            oldOpponentId: "",
        }
    },

    watch: {
        opponentNow: function() {
            if(this.opponentNow == false)
            {
                this.$emit('changeOpponentId', "");
                this.$emit('changeOldOpponentId', "");
            }
        },
    },

    beforeMount() {
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;

        axios.get("https://smada.com/api/common/player/list")
        .then(response => {
            this.playerList = response.data;
        })
        .catch(error => {
            console.log("An error occured: ", error);
        })
    },

    methods: {
        handleOpponentChoice(index) {
            // code the parent needs to have is commented out at bottom
            this.opponentId = this.playerList[index].ID
            this.$emit('changeOpponentId', this.opponentId);
            this.$emit('changeOldOpponentId', this.oldOpponentId);

            if (this.oldOpponentId !== "")
            {
                document.getElementById(this.oldOpponentId.toString()).style = "background-color: lightgreen;";
            }

            document.getElementById(this.opponentId.toString()).style = "background-color: green;";
        },
    },
}
</script>

<style scoped>
.checkbox-wrapper {
    display: flex;
    justify-content: center;
}
.checkbox-label {
    margin-top: 5px;
}

.masterminds-player-list-name-wrapper-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.masterminds-player-list-name-wrapper {
    height: 30px;
    width: 50vw;
    background-color: lightgreen;
    margin-bottom: 3vh;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}
</style>

<style>
/*
Code the Parent needs to run this component:

<OpponentSelection @changeOpponentId="this.updateOpponentId" />

    opponentId: "",

    updateOpponentId(newData) {
        this.opponentId = newData;
    },
*/
</style>