<template id="template">
    <div id="connect-game-home" :key="this.changingKey">
        <div id="connect-my-games-header-wrapper"><h3 id="connect-my-games-header">My Games:</h3></div>
        <div id="connect-games-output-text">{{ this.outputText }}</div>
        <div v-if="this.isLoading">Loading...</div>
        <div v-if="this.myGamesResponse.length == 0 && !this.isLoading" id="connect-no-games-message">Join or create a game to start playing!</div>
        <div id="connect-games-wrapper" v-for="(game) in myGamesResponse" :key="game.ID">
            <div class="connect-game-wrapper" id="connect-active-game" @click="handleGameClick(game.ID)">
                <div class="connect-my-game">
                    <div>
                        <span>Game with {{ game.OpponentName }}</span>
                        <span v-if="game.State == 'clue'">
                            <span v-if="game.Turn == this.myId">, your turn</span>
                            <span v-else>, waiting for clue</span>
                        </span>
                        <span v-else-if="game.State == 'guess'">
                            <span v-if="game.Turn == this.myId">, your turn</span>
                            <span v-else>, waiting for guess</span>
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div id="connect-create-new-wrapper">
            <RouterLink class="route" to="connect/new-game">Create new game</RouterLink>
        </div>

                <!-- Open Games Code-->
        <!-- <div><h4>Join an open game:</h4></div>
        <div id="connect-no-open-games" v-if="openGamesResponse.length == 0">
            No open games
        </div>
        <div id="connect-open-games-wrapper" v-for="(game, idx) in openGamesResponse" :key="idx">
            <div class="connect-open-game-wrapper" @click="openModel(idx)">
                <div class="connect-game-element grey-background">
                    {{ game.Name }}'s game
                </div>
                <div  class="connect-game-element multi-line-game-element lightgrey-background">
                    <div>Max Length {{ game.Rules.maxChars }}</div>
                </div>
                <div class="connect-game-element multi-line-game-element grey-background">
                    <div v-if="game.Rules.maxDups == 1">Single Letters</div>
                    <div v-if="game.Rules.maxDups == 2">Double Letters</div>
                    <div v-if="game.Rules.maxDups == 3">Triple Letters</div>
                </div>
            </div>
        </div> -->

        <!-- Model -->
        <div v-if="this.modelOpen" class="model-full-page">
            <div class="connect-model">
                <div id="close-model-wrapper">
                    <div id="close-model" @click="closeModel">X</div>
                </div>
                <p>To join this game, enter a word that fits the requirements of the game</p>
                <div id="model-textbox-wrapper">
                    <input type="text" placeholder="Enter your word" v-model="inputWord"/>
                </div>
                <div id="connect-model-button-wrapper">
                    <button id="connect-model-button" @click="joinGame">Join</button>
                </div>
                <div id="connect-model-game-rules">
                    <div>Max Length {{ this.openGamesResponse[this.gameIndex].Rules.maxChars }} </div>
                    <div v-if="this.openGamesResponse[this.gameIndex].Rules.maxDups == 1">Single Letters</div>
                    <div v-if="this.openGamesResponse[this.gameIndex].Rules.maxDups == 2">Double Letters</div>
                    <div v-if="this.openGamesResponse[this.gameIndex].Rules.maxDups == 3">Triple Letters</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import { store } from '@/stores/store';

    export default {
        name: 'MastermindsGameHome',

        data() {
            return {
                isLoading: true,
                myName: "",
                myId: "",
                outputText: "",
                myGamesResponse: [],
                openGamesResponse: [],
                gameCodeInput: "",
                changingKey: 0,
                modelOpen: false,
                gameIndex: -1,
                inputWord: "",
            }
        },

        beforeMount() {
            if (store.token == "")
            {
                router.push("/");
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
            this.myName = store.loginUser.Name;
            this.myId = store.loginUser.ID;

            // My games axios call
            axios.get("https://smada.com/api/connect/list")
            .then(response => {
                this.isLoading = false;
                for(let i = 0; i < response.data.length; i++)
                {
                    this.myGamesResponse[i] = response.data[i];
                }
            })
            .catch(error => {
                console.log("An error occured: " + error);
            });

            // Open games list axios call
        //     axios.get("https://smada.com/api/mastermind/list/open")
        //     .then(response => {
        //         for(let i = 0; i < response.data.length; i++)
        //         {
        //             this.openGamesResponse[i] = response.data[i];
        //             this.openGamesResponse[i].Rules = JSON.parse(this.openGamesResponse[i].Rules);
        //             this.openGamesResponse[i].Name = this.openGamesResponse[i].Name.slice(0, this.openGamesResponse[i].Name.indexOf(" "));
        //         }
        //     })
        //     .catch(error => {
        //         console.log("An error occured: ", error);
        //     })
        },

        methods: {
            handleGameCodeInput() {
                this.gameCodeInput = "";
            },

            handleGameClick(id) {
                router.push("/connect/game/" + id);
            },

            openModel(index) {
                this.modelOpen = true;
                this.gameIndex = index;
            },

            closeModel() {
                this.modelOpen = false;
                this.gameIndex = -1;
            },

            joinGame() {
                axios.post("https://smada.com/api/connect/join/" + this.openGamesResponse[this.gameIndex].GameID, { MyWord:  this.inputWord })
                .then(() => {
                    this.modelOpen = false;
                    this.changingKey++;
                })
                .catch(error => {
                    console.log("An error occured: " + error);
                    if(error.response)
                    {
                        this.outputText = error.response.data;
                    }
                    else
                    {
                        this.outputText = "An error occured";
                    }
                })

            }
        }
    }

</script>

<style scoped>
#connect-my-games-header {
    margin-bottom: 0px;
}

#connect-games-output-text {
    height: 20px;
    margin-bottom: 1vh;
    color: red;
}

#connect-no-games-message {
    margin-bottom: 2vh;
}

#connect-no-open-games {
    margin-bottom: 8vh;
}

#connect-games-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
}

.connect-game-wrapper {
    width: 90vw;
    height: 7vh;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connect-my-game {
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#connect-active-game {
    cursor: pointer;
}

#connect-enter-code-textbox {
    margin-bottom: 3vh;
}

#connect-join-game-submit-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
}

#connect-submit-code-button {
    height: 30px;
    width: 100px;
}

#connect-create-new-wrapper {
    margin-bottom: 7vh;
}

#connect-open-games-wrapper {
    font-size: 0.8em;
}

.connect-open-game-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90vw;
    margin-left: 5vw;
}

.connect-game-element {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
    height: 7vh;
    cursor: pointer;
}

.grey-background {
    background-color: grey;
}

.lightgrey-background {
    background-color: lightgrey;
}

/* Model styles */
.model-full-page {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.connect-model {
    height: 50vh;
    width: 80vw;
    background-color: white;
}

#close-model-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1vh;
    margin-right: 1vh;
}

#close-model {
    cursor: pointer;
}

#model-textbox-wrapper {
    margin-bottom: 2vh;
}

#connect-model-button-wrapper {
    margin-bottom: 2vh;
}
</style>