import { createRouter, createWebHashHistory } from 'vue-router'
import RegisterPage from '@/components/RegisterPage.vue'
import HomePage from '@/components/HomePage.vue'
import MainHeader from '@/components/MainHeader.vue'
import AssassinsGameHome from '@/components/Games/Assassins/AssassinsGameHome.vue'
import AssassinsGameCreate from '@/components/Games/Assassins/AssassinsGameCreate.vue'
import AssassinsGameInfo from '@/components/Games/Assassins/AssassinsGameInfo.vue'
import MastermindsGameHome from '@/components/Games/Masterminds/MastermindsGameHome.vue'
import MastermindsGameCreate from '@/components/Games/Masterminds/MastermindsGameCreate.vue'
import MastermindsGamePlayPage from '@/components/Games/Masterminds/MastermindsGamePlayPage.vue'
import MastermindsGuessHistory from '@/components/Games/Masterminds/MastermindsGuessHistory.vue'
import ConnectHome from '@/components/Games/Connect/ConnectHome'
import ConnectGameCreate from '@/components/Games/Connect/ConnectGameCreate'
import ConnectGamePlayPage from '@/components/Games/Connect/ConnectGamePlayPage'

const router = createRouter({
    history: createWebHashHistory(),
    routes: [
      // Main Page
      {
        path: '/home',
        name: 'home',
        component: HomePage,
      },
      {
        path: '/register',
        name: 'register',
        component: RegisterPage,
      },
      {
        path: '/',
        name: 'main-header',
        component: MainHeader,
      },

      // Assassins
      {
        path: '/assassins',
        name: 'assassins',
        component: AssassinsGameHome,
      },
      {
        path: '/assassins/create-game',
        name: 'assassins-game-create',
        component: AssassinsGameCreate,
      },
      {
        path: '/assassins/game-info/:id/:status',
        name: 'assassins-game-info',
        component: AssassinsGameInfo,
      },

      // Masterminds
      {
        path: '/masterminds',
        name: 'masterminds',
        component: MastermindsGameHome,
      },
      {
        path: '/masterminds/new-game',
        name: 'masterminds-game-create',
        component: MastermindsGameCreate,
      },
      {
        path: '/masterminds/game/:id/:status',
        name: 'masterminds-game',
        component: MastermindsGamePlayPage,
      },
      {
        path: '/masterminds/game/:id/:status/history',
        name: 'masterminds-guess-history',
        component: MastermindsGuessHistory,
      },

      // Connect
      {
        path: '/connect',
        name: 'connect-home',
        component: ConnectHome,
      },
      {
        path: '/connect/new-game',
        name: 'connect-game-create',
        component: ConnectGameCreate,
      },
      {
        path: '/connect/game/:id',
        name: 'connect-game-page',
        component: ConnectGamePlayPage,
      },
    ]
  })

  export default router;