<template>
    <div id="connect-nav-bar">
        <div class="connect-nav-link-wrapper" @click="this.activeBoard = 1">
            <span v-if="this.activeBoard !== 1" class="link">Board</span>
            <span v-else class="link" style="text-decoration: underline;">Board</span>
        </div>
        <div class="connect-nav-link-wrapper" id="connect-history-link-wrapper" @click="this.activeBoard = 2">
            <span v-if="this.activeBoard !== 2" class="link">History</span>
            <span v-else class="link" style="text-decoration: underline;">History</span>
        </div>
    </div>
</template>

<script>
export default {
    name: "ConnectNavBar",

    data() {
        return {
            activeBoard: 1,
        }
    },

    watch: {
        activeBoard: function(newData) {
            this.$emit('board-change', newData);
        }
    },

    methods: {

    },
}
</script>

<style scoped>
#connect-nav-bar {
    background-color: lightgreen;
    width: 100%;
    height: 5vh;
    display: grid;
    grid-template-columns: 1fr 1fr;
    cursor: pointer;
}

#connect-nav-bar > .connect-nav-link-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#connect-history-link-wrapper {
    border-left: 1px solid black;
}
</style>