<template>
    <div>
        <ConnectNavBar @board-change="handleBoardChange" />
        <div v-if="!isLoading" id="connect-output-text">{{ this.outputText }}</div>
        <div v-if="isLoading">Loading...</div>
        <div v-else-if="this.yourTurn">
            <div v-if="this.currentState == 'clue'">
                <input type="text" placeholder="Enter your clue" v-model="this.clueString"/>
                <input type="number" v-model="this.clueInt"/>
                <div id="connect-submit-clue-wrapper">
                    <button id="connect-submit-clue-button" @click="handleClueSubmit">Submit</button>
                </div>
            </div>
            <div v-if="this.currentState == 'guess'">
                Your turn to guess!
                <div v-if="this.responseData.Clues.length >= 1">
                    Your clue is
                    {{ this.responseData.Clues[this.responseData.Clues.length - 1].clue }}
                    {{ this.responseData.Clues[this.responseData.Clues.length - 1].number }}
                </div>
            </div>
        </div>
        <div v-else-if="!isLoading && !this.yourTurn">
            <div v-if="this.currentState == 'clue'">
                Waiting for your partner's clue
            </div>
            <div v-if="this.currentState == 'guess'">
                Waiting for partner to guess
            </div>
        </div>
        <div v-if="this.currentState == 'done'">
            The game is over
        </div>

        <br>
        <div v-if="!this.isLoading">
            <div v-if="this.playPageActiveBoard == 1">
                <ConnectMyBoard :listFromParent="doubleWordArr"/>
                <div v-if="this.responseData.Clues.length >= 1">
                    <div v-if="this.yourTurn && this.currentState == 'guess'">
                        <button @click="handleTurnEnd">End Turn</button>
                    </div>
                </div>
            </div>
            <div v-else-if="this.playPageActiveBoard == 2">
                <ConnectHistory />
            </div>
        </div>
    </div>
</template>

<script>
const checkTurnTimeDelay = 10000;

import axios from 'axios';
import router from '@/router';
import { store } from '@/stores/store';

import ConnectNavBar from './ConnectNavBar.vue';
import ConnectMyBoard from './ConnectTabs/ConnectMyBoard.vue';
import ConnectHistory from './ConnectTabs/ConnectHistory.vue';
export default {
    name: "ConnectGamePlayPage",

    components: {
        ConnectNavBar,
        ConnectMyBoard,
        ConnectHistory,
    },

    data() {
        return {
            isLoading: true,
            outputText: "",
            responseData: [],
            wordArr: [],
            doubleWordArr: [[], [], [], [], []],
            myName: "",
            myId: "",
            yourTurn: Boolean,
            currentState: "",
            playPageActiveBoard: 1,
            clueString: "",
            clueInt: Number,
            savedClueInt: 0,
            guessesMade: 0,
            checkTurnTimeout: 0,
            resultsDataProp: [],
            checkTurnTimeoutBool: false,
        }
    },

    beforeMount() {
        if (store.token == "")
        {
            router.push("/");
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;

        this.myName = store.loginUser.Name;
        this.myId = store.loginUser.ID;

        this.axiosPageCreate();
    },

    watch: {
        outputText(newValue) {
            if(newValue !== "")
                {
                    this.outputTextTimeout = setTimeout(() => {
                        this.outputText = "";
                    }, 3000)
                }
        }
    },

    methods: {
        axiosPageCreate() {
            this.isLoading = true;
            axios.get("https://smada.com/api/connect/" + this.$route.params.id)
            .then(response => {
                this.responseData = response.data;
                this.wordArr = response.data.WordList;
                this.currentState = response.data.State;

                // Update the prop for ConnectMyBoard
                this.resultsDataProp = response.data.WordList;

                if(this.responseData.Clues.length >= 1 && this.currentState == 'guess')
                {
                    this.guessesMade = this.responseData.Clues[this.responseData.Clues.length - 1].guesses.length;
                }
                else
                    this.guessesMade = 0;

                this.handleWordsArrayCreation();

                if(this.responseData.Turn == this.myId)
                {
                    this.yourTurn = true;
                }
                else if(this.yourTurn == Boolean)
                {
                    this.yourTurn = false;
                    this.checkTurnUpdate();
                }

                this.isLoading = false;
            })
            .catch(error => {
                console.log("An error occured: ", error);
            })
        },

        handleBoardChange(number) {
            this.playPageActiveBoard = number;
        },

        handleWordsArrayCreation() {
            let wordListIdx = 0;
            for(let i = 0; i < 5; i++)
            {
                for(let j = 0; j < 5; j++)
                {
                    this.doubleWordArr[i][j] = this.wordArr[wordListIdx];
                    wordListIdx++;
                }
            }
        },

        handleClueSubmit() {
            if(this.clueInt > 6 || this.clueInt < 1)
            {
                this.outputText = "Guess amount must be between 1 and 6";
                return
            }

            axios.post("https://smada.com/api/connect/" + this.$route.params.id + "/clue", {
                word: this.clueString,
                number: this.clueInt
            })
            .then(() => {
                this.clueString = "";
                this.outputText = "";
                this.clueInt = Number;
                this.currentState = 'guess';
                this.yourTurn = false;
                this.checkTurnUpdate();
            })
            .catch(error => {
                console.log("An error occured: ", error);
                if(error.response.data.message)
                    this.outputText = error.response.data.message;
                else if(error.response.data)
                    this.outputText = error.response.data;
                else
                    this.outputText = "Something went wrong";
            })
        },

        handleTurnEnd() {
            axios.get("https://smada.com/api/connect/" + this.$route.params.id + "/end")
            .then(() => {
                this.axiosPageCreate();
            })
            .catch(error => {
                console.log("An error occured: ", error);
            })
        },

        checkTurnUpdate() {
            if(this.yourTurn)
            {
                clearTimeout(this.checkTurnTimeout);
                this.checkTurnTimeoutBool = false;
                return
            }
            if(this.$route.params.id == undefined)
            {
                clearTimeout(this.checkTurnTimeout);
                this.checkTurnTimeoutBool = false;
                return
            }
            if(this.checkTurnTimeoutBool == true)
            {
                return
            }

            this.checkTurnTimeoutBool = true;
            this.checkTurnTimeout =  setTimeout(() => {
                axios.get("https://smada.com/api/connect/" + this.$route.params.id)
                .then(response => {
                    for(let i = 0; i < response.data.WordList.length; i++) 
                    {
                        if(response.data.WordList[i].my_result != this.resultsDataProp[i].my_result || response.data.WordList[i].opp_result != this.resultsDataProp[i].opp_result)
                        {
                            this.axiosPageCreate();
                        }
                    }

                    if(response.data.Turn == store.loginUser.ID && (response.data.State == 'clue' || response.data.State == 'guess'))
                    {
                        this.yourTurn = true;
                        this.currentState = response.data.State;
                        this.responseData = response.data;
                        clearTimeout(this.checkTurnTimeout);
                        this.checkTurnTimeoutBool = false;
                        return
                    }
                    else if(response.data.State == 'finished' || response.data.State == 'done')
                    {
                        this.currentState = response.data.State;
                        clearTimeout(this.checkTurnTimeout);
                        this.checkTurnTimeoutBool = false;
                        return
                    }
                    else
                    {
                        this.currentState = response.data.State;
                        this.responseData = response.data;
                    }
                })
                .catch(error => {
                    if(error.response && error.response.data && error.response.data != "no game here")
                        console.log("An error occured: ", error);
                    return
                })

                this.checkTurnTimeoutBool = false;
                this.checkTurnUpdate();
            }, checkTurnTimeDelay);
        },
    }
}
</script>

<style scoped>
#connect-output-text {
    height: 25px;
    color: red;
    display: flex;
    justify-content: center;
    align-items: center;
}

input[type='number'] {
    border: 2px solid black;
    border-radius: 1px;
    height: 25px;
    width: 40px;
    justify-content: center;
    margin-left: 2vw;
    text-align: center;
}

#connect-submit-clue-wrapper {
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
}
</style>