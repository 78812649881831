<template>
    <div v-if="this.responseData != ''">
        <div id="history-wrapper" v-for="(historyItem, idx) in this.responseData.Clues.slice().reverse()" :key="idx">
            <div class="history-item">
                <div class="turn-indicate-wrapper">
                    <div v-if="historyItem.game_user.user_id != this.myId" class="turn-indicate">
                        Partner's <br> Clue
                    </div>
                    <div v-else>
                        Your <br> Clue
                    </div>
                </div>
                <div class="history-clue-wrapper">
                    <div class="history-clue">{{ historyItem.clue }} <br> {{ historyItem.number }}</div>
                </div>
                <div class="guess-and-result-wrapper" 
                :style="{
                    'height': historyItem.guesses.length == 0 ? '10vh' : (historyItem.guesses.length * 10) + 'vh',
                    'display': 'grid',
                    'gridTemplateRows': historyItem.guesses.length == 0 ? '1fr' : `repeat(${historyItem.guesses.length}, 1fr)`,
                    'border-top':  '1px solid black',
                    'border-right': '1px solid black',
                    }">
                    <div 
                    class="guess-and-result"
                    v-for="(guess, idx) in historyItem.guesses" 
                    :key="idx"
                    :style="{
                        'border-bottom':  '1px solid black',
                    }"
                    >
                        <div class="history-guess-wrapper">
                            <div class="history-guess">{{ guess.guess }}</div>
                        </div>
                        <div class="history-result-wrapper">
                            <div v-if="guess.result == 'agent'" class="history-guess check-result">
                                <font-awesome-icon :icon="['fas', 'check']" />
                            </div>
                            <div v-if="guess.result == 'bystander'" class="history-guess dash-result">
                                <font-awesome-icon :icon="['fas', 'minus']" />
                            </div>
                            <div v-if="guess.result == 'assassin'" class="history-guess skull-result">
                                <font-awesome-icon :icon="['fas', 'skull']" />
                            </div>
                        </div>
                    </div>
                    <div v-if="historyItem.guesses.length == 0" class="no-guesses">
                        <i>No guesses on this clue</i>
                    </div>
                </div>
            </div>
        </div>
        <!-- <font-awesome-icon icon="fa-solid fa-arrow-down-long" />: You gave the clue
        <br>
        <font-awesome-icon icon="fa-solid fa-arrow-up-long" />: Your teammate gave the clue -->
    </div>
</template>

<script>
import axios from 'axios';
import { store } from '@/stores/store'

export default {
    name: "ConnectHistory",

    data() {
        return {
            responseData: '',
            myId: -1,
        }
    },

    beforeMount() {
        this.myId = store.loginUser.ID;
        axios.get("https://smada.com/api/connect/" + this.$route.params.id)
        .then(response => {
            this.responseData = response.data;
        })
        .catch(error => {
            console.log("An error occured: " + error);
        })
    },  

    methods: {

    },
}
</script>

<style scoped>
#history-wrapper {
    display: flex;
    justify-content: flex-start;
}
.history-item {
    width: 90%;
    margin-bottom: 2vh;
    display: grid;
    grid-template-columns: 1fr 1fr 4fr;
}

.turn-indicate-wrapper {
    font-size: 0.8em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.history-clue-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: green;
    border: 1px solid black;
}

.guess-and-result-wrapper {
    background-color: lightgreen;
}

.guess-and-result {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.history-guess-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.history-result-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow-icon {
    font-size: 2em;
}

.no-guesses {
    border-bottom: 1px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* coloring for indicator icons */
.check-result {
    font-size: 1.5em;
    color: green;
}

.skull-result {
    font-size: 1.5em;
    color: red;
}
</style>