<template>
    <div>
        <div id="game-elements-full-wrapper">
            <div class="game-element-wrapper">
                <a class="thumbnail-link" href="https://hyrum.smada.com" target="blank"><img class="image-link-image" src="../thumbnails/wordle-thumbnail.png" /></a>
                <div class="game-name">Wordle</div>
            </div>
            <div class="game-element-wrapper">
                <RouterLink class="thumbnail-link" to="assassins"><img class="image-link-image" src="../thumbnails/assassins-thumbnail.png" /></RouterLink>
                <div class="game-name">Assassins</div>
            </div>
            <div class="game-element-wrapper">
                <RouterLink class="thumbnail-link" to="masterminds"><img class="image-link-image" src="../thumbnails/masterminds-thumbnail.png" /></RouterLink>
                <div class="game-name">Masterminds</div>
            </div>
            <div class="game-element-wrapper">
                <RouterLink class="thumbnail-link" to="connect"><img class="image-link-image" src="../thumbnails/connect-thumb-small.png" /></RouterLink>
                <div class="game-name">Connect</div>
            </div>
        </div>
    </div>
</template>

<script>
import router from "../router/index.js";
import { store } from '../stores/store.js';
export default {
    name: 'HomePage',

    data() {
        return {
            name: "",
        }
    },

    beforeMount() {
        if (store.token == "")
        {
            router.push('/');
        } else {
            this.name = store.name;
        }
    },

    methods: {

    }
}
</script>

<style scoped>

#game-elements-full-wrapper {
    width: 100vw;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 3vh;
}

.game-element-wrapper {
    margin-bottom: 5vh;
}

.image-link-image {
    height: 15vh;
}


@media only screen and (max-width: 600px) {
    #game-elements-full-wrapper {
        width: 100vw;
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
</style>