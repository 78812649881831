<template>
  <div>
    <HeaderCode />
    <RouterView />
  </div>
</template>

<script>
import HeaderCode from "./components/HeaderCode.vue";
export default {
  name: 'App',
  components: {
    HeaderCode,
  },
  // data() {
  //   return {

  //   }
  // },

  // methods: {}
}

</script>

<style>
body {
  border: 0px;
  margin: 0px;
  padding: 0px;
  background-color: #fff6f1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  /* -webkit-font-smoothing: antialiased; */
  text-align: center;
  color: black;
  /* margin-top: 10vh; */
}

input[type='text'] {
  border: 2px solid black;
  border-radius: 1px;
  height: 25px;
  width: 175px;
}

input[type='password'] {
  border: 2px solid black;
  border-radius: 1px;
  height: 25px;
  width: 175px;
}

input:focus {
  outline: none;
}

.link {
  color: blue;
  cursor: pointer;
  text-decoration: none;
}

.route {
  color: blue;
  text-decoration: none;
  cursor: pointer;
}

.submit-button {
  cursor: pointer;
  width: fit-content;
  height: 25px;
}
</style>
