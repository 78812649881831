import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import mitt from 'mitt'
import {Tabs, Tab} from 'vue3-tabs-component'

// Font Awesome Imports
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Specific Icons
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faMinus } from '@fortawesome/free-solid-svg-icons'
import { faSkull } from '@fortawesome/free-solid-svg-icons'



const emitter = mitt();
library.add(faRightFromBracket)
library.add(faCheck)
library.add(faSkull)
library.add(faMinus)

const app = createApp(App)
.component('tabs', Tabs)
.component('tab', Tab)
.component('font-awesome-icon', FontAwesomeIcon)


app.use(router);

app.config.globalProperties.emitter = emitter;
app.mount('#app');