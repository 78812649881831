<template>
    <div>
        <div id="connect-board">
            <div v-for="n in 5" :key="n - 1" class="connect-play-page-row-wrapper">
                <div v-for="o in 5" :key="o - 1" class="connect-play-page-word-wrapper">
                    <ConnectWord
                    class="connect-word"
                    :key="this.keyArr[((n - 1) * 5) + (o - 1)]"
                    :ref="`card${((n - 1) * 5) + (o - 1)}`"
                    :class="{
                        'agent': this.wordResultsList[n - 1][o - 1].my_status == 'agent',
                        'found-agent': this.wordResultsList[n - 1][o - 1].my_result == 'agent' || this.wordResultsList[n - 1][o - 1].opp_result == 'agent',
                        'bystander': this.wordResultsList[n - 1][o - 1].my_status == 'bystander',
                        'i-found-bystander': this.wordResultsList[n - 1][o - 1].my_result == 'bystander',
                        'they-found-bystander': this.wordResultsList[n - 1][o - 1].opp_result == 'bystander',
                        'assassin': this.wordResultsList[n - 1][o - 1].my_status == 'assassin',
                        'found-assassin': this.wordResultsList[n - 1][o - 1].my_result == 'assassin' || this.wordResultsList[n - 1][o - 1].opp_result == 'assassin',
                    }"
                    :word="this.wordResultsList[n - 1][o - 1].word"
                    :cardStatus="this.wordResultsList[n - 1][o - 1].my_status"
                    :resultStatus="this.wordResultsList[n - 1][o - 1].my_result"
                    :opponentResult="this.$parent.wordArr[((n - 1) * 5) + (o - 1)].opp_result"
                    :cardNumber="((n - 1) * 5) + (o - 1)"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
require('./ConnectBoardStyles/ConnectBoardStyles.css');

import ConnectWord from '../ConnectWord.vue';
export default {
    name: "ConnectMyBoard",

    components: {
        ConnectWord,
    },

    props: {
        listFromParent: Array,
    },

    data() {
        return {
            keyArr: [],
            wordResultsList: this.listFromParent,
        }
    },

    beforeMount() {
        for(let i = 0; i < 25; i++)
        {
            this.keyArr[i] = 0;
        }
    },

    methods: {

    }
}
</script>

<style scoped>
.assassin {
    font-weight: 900;
    color: red;
}
.i-found-bystander {
    background-image: url(./ConnectBoardStyles/BystanderArrows/my-bystander.png);
    background-size: 100% 100%;
}

.they-found-bystander {
    background-image: url(./ConnectBoardStyles/BystanderArrows/opponent-bystander.png);
    background-size: 100% 100%;
}

.i-found-bystander.they-found-bystander {
    background-image: none;
    background-color: rgb(170, 170, 170);
    color: rgb(170, 170, 170);
}
.agent {
    font-weight: 900;
    color: green;
}

.found-agent {
    background-image: none;
    background-color: green;
    color: green;
}

.found-assassin {
    background-image: none;
    color: black;
    background-color: black;
}
</style>