<template>
  <div class="header">
    <h1>Welcome to smada.com</h1>
    <div id="sign-in-output-text">{{ this.outputText }}</div>
  </div>
  <form id="login-form" @submit="SignInClick">
    <div class="sign-in-input-wrapper" id="username-wrapper"><input type="text" class="sign-in-input" id="email-textbox" placeholder="Enter email" v-model="email" required/></div>
    <div class="sign-in-input-wrapper" id ="password-wrapper"><input class="sign-in-input" type="password" id="password-textbox" placeholder="Enter password" v-model="password" required/></div>
    <div class="sign-in-button-wrapper">
      <button class="submit-button" @click="SignInClick">Sign In</button>
    </div>
    <br>
    <RouterLink class="route" to="register">Click here to sign up!</RouterLink>
  </form>
</template>

<script>
import axios from "axios";
import router from "../router/index.js";
import { store } from '../stores/store.js';
export default {
  name: 'MainHeader',
  props: {
    title: String
  },

  data() {
    return {
      email: "",
      password: "",
      outputText: "",
    }
  },

  watch: {
    outputText(newValue) {
      if(newValue !== "")
          {
              this.outputTextTimeout = setTimeout(() => {
                  this.outputText = "";
              }, 3000)
          }
      }
  },


  methods: {
    SignInClick(e) {
      e.preventDefault();
      if (this.email == "")
      {
        this.outputText = "Please fill out all fields";
        document.getElementById("email-textbox").focus();
        return;
      }
      else if (this.password == "")
      {
        this.outputText = "Please fill out all fields";
        document.getElementById("password-textbox").focus();
        return;
      }
      else if (!this.email.includes('@'))
      {
        this.outputText = "Please enter a valid email";
        return;
      }
      axios.post("https://smada.com/api/public/auth", { email: this.email, password: this.password })
      .then(response => {
        if(response.data.token)
        {
          localStorage.setItem("user_data", JSON.stringify(response.data));

          store.token = response.data.token;
          store.loginUser = response.data.me;
          store.tokenExpires = response.data.expires;
          store.name = response.data.me.Name;
          router.push('/home');
        }
      })
      .catch(error => {
        console.log("An error occured: " + error);
        this.outputText = error.response.data.message;
      })
    },

    RegisterLinkClick() {
      this.$parent.registering = true;
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#login-form {
  display: grid;
  grid-template-rows: 2fr 2.5fr 0.5fr 0.5fr;
}

#sign-in-output-text {
  color: red;
  height: 20px;
}
.sign-in-input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
