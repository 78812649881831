<template>
    <div>
        <h2>Create a new game</h2>
        <div id="game-create-output-text">{{ this.outputText }}</div>
        <form>
            <input type="text" placeholder="Enter the Game's name" maxlength="25" v-model="gameName"/><br><br>
            <input type="checkbox" id="admin-game-join-checkbox"/>
            <label for="admin-game-join-checkbox">Join this game</label><br><br>
            <button type="button" @click="handleGameCreate">Create Game</button>
        </form>
    </div>
</template>

<script>
import axios from 'axios';
import { store } from 'src/stores/store.js';
import router from 'src/router/index.js'

export default {
    name: 'AssassinsGameCreate',
    data() {
        return {
            gameName: "",
            outputText: "",
        }
    },

    watch: {
        outputText(newValue) {
            if(newValue !== "")
                {
                    this.outputTextTimeout = setTimeout(() => {
                        this.outputText = "";
                    }, 3000)
                }
        }
    },

    methods: {
        handleGameCreate() {
            if (this.gameName == "")
            {
                this.outputText = "Please enter a name";
                return;
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
            axios.post("https://smada.com/api/assassins/admin/new", { name: this.gameName, auto_join: document.getElementById("admin-game-join-checkbox").checked })
            .then(() => {
                router.push('/assassins');
            })
            .catch(error => {
                console.log("An error occured: " + error);

                if(error.response.data.message)
                    this.outputText = error.response.data.message;
                else
                    this.outputText = "Something went wrong";
            })
        }
    }
}

</script>

<style>
#game-create-output-text {
    color: red;
    height: 20px;
}
</style>