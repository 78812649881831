<template>
    <div>
        <h3 v-if="this.currentGameStatus == 'pending'">
            <div id="current-players">Current Players:</div>
            <div v-for="(player, idx) in playersList" :key="idx">
                {{ player }}
            </div>
        </h3>
        <h3 v-if="this.currentGameStatus == 'active' && this.initialResponseData.me.Killed.Valid == false && this.initialResponseData.target !== initialResponseData.me.User.name">
            <h3 v-if="!this.killAlterForRefresh">Your target is {{ this.initialResponseData.target }}</h3>
            <h3 v-if="this.killAlterForRefresh">{{ this.newTarget }}</h3>
            <div>Your kill code is {{ this.initialResponseData.me.code }}</div>
            <div id="active-game-output-text">{{ outputText }}</div>
            <input id="kill-code-text-box" type="text" placeholder="Enter player's kill code" v-model="inputKillCode" required/>
            <br><br>
            <button type="button" @click="handleKillCodeCheck">Validate Kill</button>

            <br><br>

            <div class="smaller-text">If a player kills you, you must give them your kill code.</div>
            <div class="full-width-wrapper">
                <div class="smaller-text" id="successful-assassination">Should you succeed in assassinating your target, enter their kill code into the target box above and you will receive your next target.</div>
            </div>

            <br><br>

            <div class="full-width-wrapper">
                <div class="smaller-text" id="successful-assassination">Should you discover your assassin attempting to assassinate you, you may defend your life, liberty, and pursuit of bragging rights by killing them first.  With their last dying breaths they must admit their dismal failure and tell you their kill code. Solemnly enter their code into your target box. This will alert all members of their demise…. (especially the person who was assigned to assassinate them so that they may check to see who their new target is.  Warning!   You Are Not Safe Yet!!!!   A new assassin will be out to get you!!
</div>
            </div>
        </h3>
        <h3 v-if="this.currentGameStatus == 'active' && this.initialResponseData.me.Killed.Valid == true">
            <div>You are dead.  Do better next time.</div>
        </h3>
        <h1 v-if="this.currentGameStatus == 'active' && this.initialResponseData.target == initialResponseData.me.User.name">
            You won!
        </h1>
        <br>
        <div v-if="this.isAnAdmin && this.initialResponseData.Game.Status == 'pending'" class="link" @click="startGame">Start Game</div>
    </div>
</template>

<script>
import axios from 'axios';
import { store } from '@/stores/store';
import router from 'src/router/index.js';
export default {
  props: {
  },
    name: 'AssassinsGameInfo',

    data() {
        return {
            playerName: "",
            initialResponseData: {},
            playersList: [],
            isAnAdmin: false,
            currentGameStatus: "",
            gameId: -1,
            outputText: "",
            inputKillCode: "",
            killAlterForRefresh: false,
            newTarget: "",
        }
    },

    watch: {
        outputText(newValue) {
            if(newValue !== "")
                {
                    this.outputTextTimeout = setTimeout(() => {
                        this.outputText = "";
                    }, 3000)
                }
        }
    },

    beforeMount() {
        this.playerName = store.loginUser.Name;
        this.gameId = parseInt(this.$route.params.id);
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
        if (this.$route.params.status == 'pending')
        {
            axios.get("https://smada.com/api/assassins/public/game/" + this.gameId)
            .then(response => {
                this.currentGameStatus = this.$route.params.status;
                this.initialResponseData = response.data;
                if (store.loginUser.Role == 'admin')
                {
                    this.isAnAdmin = true;
                }
                this.writeNamesToArray();
            })
            .catch(error => {
                console.log("An error occured: " + error);
            })
        }
        else if (this.$route.params.status == 'active')
        {
            axios.get("https://smada.com/api/assassins/game/" + this.gameId)
            .then(response => {
                this.currentGameStatus = this.$route.params.status;
                this.initialResponseData = response.data;
            })
            .catch(error => {
                console.log("An error occured: " + error);
            })
        }
    },

    methods: {
        writeNamesToArray() {
            for(let i = 0; i < this.initialResponseData.Players.length; i++)
            {
                this.playersList[i] = this.initialResponseData.Players[i].User.Name;
            }
        },

        startGame() {
            axios.get("https://smada.com/api/assassins/admin/game/" + this.initialResponseData.Game.ID + "/start")
            .then(() => {
                router.push('/assassins');
            })
            .catch(error => {
                console.log("An error occured: " + error);
                console.log(error.response.data.message);
                this.outputText = error.response.data.message;
            })

        },

        handleKillCodeCheck() {
            if (this.inputKillCode == "")
            {
                this.outputText = "Enter a kill code first";
                return
            }
            axios.post("https://smada.com/api/assassins/game/" + this.gameId + "/kill", { code: this.inputKillCode })
            .then((response) => {
                this.killAlterForRefresh = !this.killAlterForRefresh;
                this.newTarget = response.data.message;
                this.inputKillCode = "";
                this.$router.go(0);
            })
            .catch(error => {
                console.log("An error occured: " + error);
                if (error.response)
                {
                    this.outputText = error.response.data.message;
                }
                else
                {
                    this.outputText = "Something went wrong";
                }
            })
        }
    }
}

</script>

<style>
#player-name {
    display: flex;
    justify-content: flex-end;
}

#current-players {
    text-decoration: underline;
    margin-bottom: 2vh;
}

#active-game-output-text {
    color: red;
    height: 25px;
}

#successful-assassination {
    width: 50vw;
}


.smaller-text {
    font-size: 0.75em;
}

.full-width-wrapper {
    width: 100vw;
    display: flex;
    justify-content: center;
}
</style>