<template id="template">
    <div id="masterminds-game-home" :key="this.changingKey">
        <div id="masterminds-my-games-header-wrapper"><h3 id="masterminds-my-games-header">My Games:</h3></div>
        <div id="masterminds-games-output-text">{{ this.outputText }}</div>
        <div v-if="this.isLoading">Loading...</div>
        <div v-if="this.myGamesResponse.length == 0 && !this.isLoading" id="masterminds-no-games-message">Join or create a game to start playing!</div>
        <div id="masterminds-games-wrapper" v-for="(game) in myGamesResponse" :key="game.ID">
            <div class="masterminds-game-wrapper" v-if="game.User2Name == ''">
                <div class="masterminds-my-game">Waiting for opponent</div>
            </div>
            <div v-else class="masterminds-game-wrapper" id="masterminds-active-game" @click="handleGameClick(game.ID, game.Status)">
                <div class="masterminds-my-game">
                    <div v-if="game.User2Name !== this.myName && game.Status == 'active'">Game with {{ game.User2Name }}</div>
                    <div v-else-if="game.User2Name == this.myName && game.Status == 'active'">Game with {{ game.User1Name }}</div>
                    <div v-else-if="game.Turn == this.myId && game.Status == 'pending'">
                        <div v-if="game.User1Name == this.myName">Waiting on {{ game.User2Name }}</div>
                        <div v-if="game.User1Name !== this.myName">Waiting on {{ game.User1Name }}</div>
                    </div>
                    <div v-else-if="game.Turn !== this.myId && game.Status == 'pending'">
                        <div v-if="game.User1Name == this.myName">{{ game.User2Name }} wants to play with you!</div>
                        <div v-if="game.User1Name !== this.myName">{{ game.User1Name }} wants to play with you!</div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div><input id="masterminds-enter-code-textbox" type="text" placeholder="Enter code to join a game" v-model="gameCodeInput" /></div>
        <div id="masterminds-join-game-submit-wrapper"><button id="masterminds-submit-code-button"  @click="handleGameCodeInput">Join Game</button></div> -->
        <div id="masterminds-create-new-wrapper">
            <RouterLink class="route" to="masterminds/new-game">Create new game</RouterLink>
        </div>

        <div><h4>Join an open game:</h4></div>
        <div id="masterminds-no-open-games" v-if="openGamesResponse.length == 0">
            No open games
        </div>
        <div id="masterminds-open-games-wrapper" v-for="(game, idx) in openGamesResponse" :key="idx">
            <div class="masterminds-open-game-wrapper" @click="openModel(idx)">
                <div class="masterminds-game-element grey-background">
                    {{ game.Name }}'s game
                </div>
                <div  class="masterminds-game-element multi-line-game-element lightgrey-background">
                    <div>Max Length {{ game.Rules.maxChars }}</div>
                </div>
                <div class="masterminds-game-element multi-line-game-element grey-background">
                    <div v-if="game.Rules.maxDups == 1">Single Letters</div>
                    <div v-if="game.Rules.maxDups == 2">Double Letters</div>
                    <div v-if="game.Rules.maxDups == 3">Triple Letters</div>
                </div>
            </div>
        </div>

        <!-- Model -->
        <div v-if="this.modelOpen" class="model-full-page">
            <div class="masterminds-model">
                <div id="close-model-wrapper">
                    <div id="close-model" @click="closeModel">X</div>
                </div>
                <p>To join this game, enter a word that fits the requirements of the game</p>
                <div id="model-textbox-wrapper">
                    <input type="text" placeholder="Enter your word" v-model="inputWord"/>
                </div>
                <div id="masterminds-model-button-wrapper">
                    <button id="masterminds-model-button" @click="joinGame">Join</button>
                </div>
                <div id="masterminds-model-game-rules">
                    <div>Max Length {{ this.openGamesResponse[this.gameIndex].Rules.maxChars }} </div>
                    <div v-if="this.openGamesResponse[this.gameIndex].Rules.maxDups == 1">Single Letters</div>
                    <div v-if="this.openGamesResponse[this.gameIndex].Rules.maxDups == 2">Double Letters</div>
                    <div v-if="this.openGamesResponse[this.gameIndex].Rules.maxDups == 3">Triple Letters</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import { store } from '@/stores/store';

    export default {
        name: 'MastermindsGameHome',

        data() {
            return {
                isLoading: true,
                myName: "",
                myId: "",
                outputText: "",
                myGamesResponse: [],
                openGamesResponse: [],
                gameCodeInput: "",
                changingKey: 0,
                modelOpen: false,
                gameIndex: -1,
                inputWord: "",
            }
        },

        watch: {
            outputText(newValue) {
            if(newValue !== "")
                {
                    this.outputTextTimeout = setTimeout(() => {
                        this.outputText = "";
                    }, 3000)
                }
            }
        },

        beforeMount() {
            if (store.token == "")
            {
                router.push("/");
            }
            axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
            this.myName = store.loginUser.Name;
            this.myId = store.loginUser.ID;

            // My games axios call
            axios.get("https://smada.com/api/mastermind/list")
            .then(response => {
                this.isLoading = false;
                for(let i = 0; i < response.data.length; i++)
                {
                    this.myGamesResponse[i] = response.data[i];
                }
            })
            .catch(error => {
                console.log("An error occured: " + error);
            });

            // Open games list axios call
            axios.get("https://smada.com/api/mastermind/list/open")
            .then(response => {
                for(let i = 0; i < response.data.length; i++)
                {
                    this.openGamesResponse[i] = response.data[i];
                    this.openGamesResponse[i].Rules = JSON.parse(this.openGamesResponse[i].Rules);
                    this.openGamesResponse[i].Name = this.openGamesResponse[i].Name.slice(0, this.openGamesResponse[i].Name.indexOf(" "));
                }
            })
            .catch(error => {
                console.log("An error occured: ", error);
            })
        },

        methods: {
            handleGameCodeInput() {
                this.gameCodeInput = "";
            },

            handleGameClick(id, status) {
                router.push("/masterminds/game/" + id + "/" + status);
            },

            openModel(index) {
                this.modelOpen = true;
                this.gameIndex = index;
            },

            closeModel() {
                this.modelOpen = false;
                this.gameIndex = -1;
            },

            joinGame() {
                axios.post("https://smada.com/api/mastermind/join/" + this.openGamesResponse[this.gameIndex].GameID, { MyWord:  this.inputWord })
                .then(() => {
                    this.modelOpen = false;
                    this.changingKey++;
                })
                .catch(error => {
                    console.log("An error occured: " + error);
                    if(error.response)
                    {
                        this.outputText = error.response.data;
                    }
                    else
                    {
                        this.outputText = "An error occured";
                    }
                })

            }
        }
    }

</script>

<style scoped>
#masterminds-my-games-header {
    margin-bottom: 0px;
}

#masterminds-games-output-text {
    height: 20px;
    margin-bottom: 1vh;
    color: red;
}

#masterminds-no-games-message {
    margin-bottom: 2vh;
}

#masterminds-no-open-games {
    margin-bottom: 8vh;
}

#masterminds-games-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 5vh;
}

.masterminds-game-wrapper {
    width: 90vw;
    height: 7vh;
    background-color: lightgrey;
    display: flex;
    justify-content: center;
    align-items: center;
}

.masterminds-my-game {
    height: 7vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

#masterminds-active-game {
    cursor: pointer;
}

#masterminds-enter-code-textbox {
    margin-bottom: 3vh;
}

#masterminds-join-game-submit-wrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 2vh;
}

#masterminds-submit-code-button {
    height: 30px;
    width: 100px;
}

#masterminds-create-new-wrapper {
    margin-bottom: 7vh;
}

#masterminds-open-games-wrapper {
    font-size: 0.8em;
}

.masterminds-open-game-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 90vw;
    margin-left: 5vw;
}

.masterminds-game-element {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3vh;
    height: 7vh;
    cursor: pointer;
}

.grey-background {
    background-color: grey;
}

.lightgrey-background {
    background-color: lightgrey;
}

/* Model styles */
.model-full-page {
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.masterminds-model {
    height: 50vh;
    width: 80vw;
    background-color: white;
}

#close-model-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 1vh;
    margin-right: 1vh;
}

#close-model {
    cursor: pointer;
}

#model-textbox-wrapper {
    margin-bottom: 2vh;
}

#masterminds-model-button-wrapper {
    margin-bottom: 2vh;
}
</style>