<template>
    <div>
        <h3>Customize your Game</h3>
        <div id="masterminds-game-create-output-text">{{ this.outputText }}</div>
        <div><label for="masterminds-word-char-max">Max Word Length</label></div>
        <select name="Max Characters" id="masterminds-word-char-max" v-model="maxCharacters">
            <option value="" disabled selected>Max Characters</option>
            <option v-for="number in 8" :key="number + 4" :value="number + 4">
                {{ number + 4 }}
            </option>
        </select>
        <div><br></div>
        <div><label for="masterminds-word-duplicate-max">Max of Each Letter</label></div>
        <select name="Max Duplicates" id="masterminds-word-duplicate-max" v-model="maxEachCharacter">
            <option value="" disabled selected>Max Duplicates</option>
            <option v-for="number in 3" :key="number - 1" :value="number - 1">
                {{ number }} of each
            </option>
        </select>
        <div><br></div>
        <div><input type="text" placeholder="Enter your word" v-model="inputWord"/></div>
        <br>
        <div><button @click="MastermindsGameCreateClick">Create Game</button></div>
        <br>

        <OpponentSelection
            @changeOpponentId="this.updateOpponentId"
        />
    </div>
</template>

<script>
import axios from 'axios';
import router from "@/router";
import { store } from '@/stores/store';

import OpponentSelection from '../../../components/MultiGameComponents/OpponentSelection.vue';

export default {
    name: "MastermindsGameCreate",

    components: {
        OpponentSelection,
    },

    data() {
        return {
            outputText: "",
            maxCharacters: -1,
            maxEachCharacter: -1,
            inputWord: "",
            opponentId: "",
        }
    },

    beforeMount() {
        if (store.token == "")
        {
            router.push("/");
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
    },

    methods: {
        MastermindsGameCreateClick() {
            if (this.maxCharacters == -1 || this.maxEachCharacter == -1 || this.inputWord == "")
            {
                this.outputText = "Please fill all fields"
                return
            }
            axios.post("https://smada.com/api/mastermind/create",
             {
                maxChars: this.maxCharacters,
                maxDups: this.maxEachCharacter + 1,
                myWord: this.inputWord,
                opponentId: this.opponentId,
             }
            )
            .then(() => {
                router.push("/masterminds");
            })
            .catch(error => {
                console.log("An error occured: ", error);
                if(error.response)
                    this.outputText = error.response.data;
            })
        },

        updateOpponentId(newData) {
            this.opponentId = newData;
        },
    }

}
</script>

<style scoped>
#masterminds-game-create-output-text {
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: red;
}
</style>