<template>
    <div>
        <div id="output-text">{{ this.outputText }}</div>
        <h3>Invite a friend to play!</h3>
        <button id="connect-game-create-button" @click="handleStartClick">Begin Game</button>
        <OpponentSelection @changeOpponentId="this.updateOpponentId" />
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import { store } from '@/stores/store';

import OpponentSelection from '../../MultiGameComponents/OpponentSelection.vue'
export default {
    name: "ConnectGameCreate",

    components: {
        OpponentSelection,
    },

    data() {
        return {
            outputText: "",
            opponentId: "",
        }
    },

    beforeMount() {
        if (store.token == "")
        {
            router.push("/");
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
    },

    methods: {
        updateOpponentId(newData) {
            this.opponentId = newData;
        },

        handleStartClick() {
            if(this.opponentId == "")
            {
                this.outputText = "You need to choose an opponent first";
                return
            }

            axios.post("https://smada.com/api/connect/new", { user_id: this.opponentId })
            .then(() => {
                router.back();
            })
            .catch(error => {
                console.log("An error occured: ", error);

                if(error.response.message)
                {
                    this.outputText = error.response.message;
                }
            })
        }
    }
}
</script>

<style scoped>
#output-text {
    height: 20px;
    margin-top: 1vh;
    color: red;
}

#connect-game-create-button {
    margin-bottom: 2vh;
}
</style>