<template>
    <div>
        <div id="connect-word-element-box" @click="handleWordClick">
            <div id="connect-word-wrapper">
                <div id="connect-word-element">{{ word }}</div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import { store } from '@/stores/store';

export default {
    name: "ConnectWord",

    props: {
        word: String,
        cardStatus: String,
        resultStatus: String,
        opponentResult: String,
        cardNumber: Number,
    },

    data() {
        return {
            localResult: "",
        }
    },

    beforeMount() {
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
    },

    mounted() {
        this.localResult = this.$props.resultStatus;
        if(this.$props.opponentResult !== "")
            this.localResult = this.$props.opponentResult;
    },

    methods: {
        handleWordClick() {
            if(this.$parent.$parent.yourTurn == false )
            {
                this.$parent.$parent.outputText = "It's not your turn";
                return
            }

            if(this.$parent.$parent.currentState !== 'guess')
            {
                this.$parent.$parent.outputText = "It's your turn to give a clue";
                return
            }

            if(this.localResult !== "")
            {
                if(this.$props.opponentResult !== 'bystander')
                {
                    return
                }
                else if(this.cardNumber <= 0)
                {
                    this.$parent.$parent.outputText = "Please enter a number 1 or more";
                    return
                }
            }

            this.$parent.$parent.outputText = "";
            this.$parent.$parent.isLoading = true;

            axios.post("https://smada.com/api/connect/" + this.$route.params.id + "/guess", { card_number: this.$props.cardNumber })
            .then(response => {
                this.$parent.$parent.isLoading = false;
                this.$parent.$parent.doubleWordArr[Math.floor(this.$props.cardNumber / 5)][this.$props.cardNumber % 5].my_result = response.data.result;
                this.$parent.$parent.guessesMade++;

                if(this.$parent.$parent.guessesMade > this.$parent.$parent.responseData.Clues[this.$parent.$parent.responseData.Clues.length - 1].number)
                {
                    this.$parent.$parent.guessesMade = 0;
                    this.$parent.$parent.axiosPageCreate();
                }

                if(response.data.result == 'bystander')
                {
                    this.$parent.$parent.axiosPageCreate();
                }
            })
            .catch(error => {
                console.log("An error occured: ", error);

                if(error.response)
                    this.$parent.$parent.outputText = error.response.data.message;
                else
                    this.$parent.$parent.outputText = "Something went wrong";
            })
        },
    }
}
</script>

<style scoped>
#connect-word-element-box {
    width: 3vw;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>