<template>
    <div id="masterminds-only-keyboard-wrapper">
        <!--Model Pop Up-->
        <div v-if="this.modelUp" id="masterminds-model-wrapper">
            <div id="masterminds-pop-up-box">
                <div id="masterminds-close-wrapper">
                    <div @click="handleModelClose">
                        <span>x</span>
                    </div>
                </div>
                <div id="masterminds-pop-up-selected-letter">{{ this.popUpLetter.toUpperCase() }}</div>
                <div id="masterminds-color-boxes-wrapper">
                    <div id="pop-up-red-box-wrapper" @click="handleColorClick">
                        <div id="pop-up-red-box" class="pop-up-color-box">Red</div>
                    </div>
                    <div id="pop-up-yellow-box-wrapper" @click="handleColorClick">
                        <div id="pop-up-yellow-box" class="pop-up-color-box">Yellow</div>
                    </div>
                    <div id="pop-up-green-box-wrapper" @click="handleColorClick">
                        <div id="pop-up-green-box" class="pop-up-color-box">Green</div>
                    </div>
                </div>
                <div id="masterminds-clear-color" @click="handleColorClick">Clear</div>
            </div>
        </div>


        <!--On Screen Keyboard-->
        <div v-if="gameStatus == 'active'" id="masterminds-keyboard-wrapper-wrapper">
            <div id="masterminds-keyboard-wrapper">
                <div class="top-row-wrapper">
                    <div id="q">Q</div><div id="w">W</div><div id="e">E</div><div id="r">R</div>
                    <div id="t">T</div><div id="y">Y</div><div id="u">U</div><div id="i">I</div>
                    <div id="o">O</div><div id="p">P</div>
                </div>
                <br>
                <div class="middle-row-wrapper">
                    <div id="a">A</div><div id="s">S</div><div id="d">D</div><div id="f">F</div>
                    <div id="g">G</div><div id="h">H</div><div id="j">J</div><div id="k">K</div>
                    <div id="l">L</div>
                </div>
                <br>
                <div class="bottom-row-wrapper">
                    <div id="Backspace" @click="this.emitter.emit('keyboard-key-press', {'keyPressed': 'Backspace'})">Back</div>
                    <div id="z">Z</div><div id="x">X</div><div id="c">C</div><div id="v">V</div>
                    <div id="b">B</div><div id="n">N</div><div id="m">M</div>
                    <div id="Enter" @click="this.emitter.emit('keyboard-key-press', {'keyPressed': 'Enter'});">Enter</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
import { store } from '@/stores/store';

export default {
    name: "MastermindsKeyboard",
    props: ['gameStatus'],

    data() {
        return {
            holdTimeout: 0,
            letterWasHeld: false,
            modelUp: false,
            popUpLetter: "",
            lettersColorsValues: {},
        }
    },

    watch: {
        gameStatus: function() {
            axios.get("https://smada.com/api/mastermind/game/" + this.$route.params.id + "/letters")
            .then(response => {
                this.lettersColorsValues = JSON.parse(response.data);
                this.updateHistoryColors();

                // Add event listeners to keyboard
                if(document.getElementById("a"))
                {
                    for (let i = 97; i < 123; i++)
                    {
                        let currentElement = document.getElementById(String.fromCharCode(i));
                        currentElement.addEventListener("touchstart", this.onScreenClick);
                        currentElement.addEventListener("touchend", this.clearMyTimeout);
                        currentElement.addEventListener("mousedown", this.onScreenClick);
                        currentElement.addEventListener("mouseup", this.clearMyTimeout);
                    }
                }
            })
            .catch(error => {
                console.log("An error occured: ", error);
                if (error.response)
                {
                    this.outputText = error.response.data;
                }
                else
                {
                    this.outputText = "Something went wrong";
                }
            });
        }
    },

    beforeMount() {
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
    },

    mounted() {
        // document.addEventListener("keydown", this.handleKeyPress);
        this.emitter.on('keyboard-key-press', (e) => {
            if(!this.$parent.$parent.yourTurn)
            {
                return
            }

            if(e.keyPressed == 'Enter' || e.keyPressed == 'Backspace')
            {
                this.$parent.handleSpecialKeyValues(e.keyPressed);
            }
            else
            {
                this.$parent.$parent.handleLetterValues(e.keyPressed);
            }
        })
    },

    beforeUnmount() {
        if(document.getElementById("a"))
        {
            for (let i = 97; i < 123; i++)
            {
                let currentElement = document.getElementById(String.fromCharCode(i));
                currentElement.removeEventListener("touchstart", this.onScreenClick);
                currentElement.removeEventListener("touchend", this.clearMyTimeout);
                currentElement.addEventListener("mousedown", this.onScreenClick);
                currentElement.addEventListener("mouseup", this.clearMyTimeout);
            }
        }

        document.removeEventListener("keydown", this.handleKeyPress);
        clearTimeout(this.checkTurnTimeout);
        this.emitter.off('keyboard-key-press');
    },

    methods: {
        onScreenClick(e) {
            e.preventDefault();
            this.holdTimeout = setTimeout(() => {
                this.handleHoldLetter(e.target.id);
                return
            }, 600);
        },

        handleHoldLetter(letter) {
            this.letterWasHeld = true;
            if(this.popUpLetter == letter)
            {
                this.modelUp = false;
                this.popUpLetter = "";
                this.changeModelStyles();
                return
            }
            this.modelUp = true;
            this.popUpLetter = letter;
            this.changeModelStyles();
        },

        clearMyTimeout(e) {
            clearTimeout(this.holdTimeout);
            if(this.letterWasHeld)
            {
                this.letterWasHeld = false;
                return
            }

            if(e.target.id.toUpperCase().charCodeAt(0) >= 65 && e.target.id.toUpperCase().charCodeAt(0) <= 90  && e.target.id.length == 1)
            {
                this.emitter.emit('keyboard-key-press', {'keyPressed': e.target.id.toUpperCase()})
            }
            else if(e.target.id == 'Enter')
            {
                this.emitter.emit('keyboard-key-press', {'keyPressed': 'Enter'})
            }
            else if(e.target.id == 'Backspace')
            {
                this.emitter.emit('keyboard-key-press', {'keyPressed': 'Backspace'})
            }
        },

        handleColorClick(e) {
            if(e.target.innerText == "Red")
            {
                document.getElementById(this.popUpLetter).style = "background-color: red;";
            }
            else if(e.target.innerText == "Yellow")
            {
                document.getElementById(this.popUpLetter).style = "background-color: yellow;";
            }
            else if(e.target.innerText == "Green")
            {
                document.getElementById(this.popUpLetter).style = "background-color: green;";
            }
            else if(e.target.innerText == "Clear")
            {
                document.getElementById(this.popUpLetter).style = "background-color: white;";
            }

            //All that stuff that was supposed to be on unmount
            for (let i = 97; i < 123; i++)
            {
                if(document.getElementById(String.fromCharCode(i)).style.backgroundColor == 'red')
                {
                    this.lettersColorsValues[String.fromCharCode(i)] = 1;
                }
                else if(document.getElementById(String.fromCharCode(i)).style.backgroundColor == 'yellow')
                {
                    this.lettersColorsValues[String.fromCharCode(i)] = 2;
                }
                else if(document.getElementById(String.fromCharCode(i)).style.backgroundColor == 'green')
                {
                    this.lettersColorsValues[String.fromCharCode(i)] = 3;
                }
                else if(document.getElementById(String.fromCharCode(i)).style.backgroundColor == 'white')
                {
                    this.lettersColorsValues[String.fromCharCode(i)] = 0;
                }
            }

            axios.post("https://smada.com/api/mastermind/game/" + this.$route.params.id + "/letters", this.lettersColorsValues)
            .then(response => {
                this.lettersColorsValues = JSON.parse(response.data);
                this.$emit('colorUpdate', this.lettersColorsValues);
            })
        },

        updateHistoryColors() {
            // Fill state object with letter values
            if(document.getElementById("a") !== null)
            {
                for (let i = 97; i < 123; i++)
                {
                    if(this.lettersColorsValues[String.fromCharCode(i)] == 1)
                    {
                        document.getElementById(String.fromCharCode(i)).style = "background-color: red;";
                    }
                    else if(this.lettersColorsValues[String.fromCharCode(i)] == 2)
                    {
                        document.getElementById(String.fromCharCode(i)).style = "background-color: yellow;";
                    }
                    else if(this.lettersColorsValues[String.fromCharCode(i)] == 3)
                    {
                        document.getElementById(String.fromCharCode(i)).style = "background-color: green;";
                    }
                    else if(this.lettersColorsValues[String.fromCharCode(i)] == 0)
                    {
                        document.getElementById(String.fromCharCode(i)).style = "background-color: white;";
                    }
                }
            }
        },

        changeModelStyles() {
            if(this.modelUp && document.getElementById("masterminds-only-keyboard-wrapper"))
            {
                let currentElement = document.getElementById("masterminds-only-keyboard-wrapper");
                currentElement.removeAttribute('id');
                currentElement.setAttribute('id', 'masterminds-model-and-keyboard-wrapper');
            }
            else if(this.modelUp)
            {
                return
            }
            else
            {
                let currentElement = document.getElementById("masterminds-model-and-keyboard-wrapper");
                currentElement.removeAttribute('id');
                currentElement.setAttribute('id', 'masterminds-only-keyboard-wrapper');
            }
        },

        handleModelClose() {
            this.modelUp = !this.modelUp;
            this.popUpLetter = '';
            this.changeModelStyles();
        }
    }
}
</script>

<style scoped>
#masterminds-model-and-keyboard-wrapper {
    display: grid;
    grid-template-rows: 13fr 15fr;
}

#masterminds-only-keyboard-wrapper {
    height: 100%;
}

/* Pop up styles */
#masterminds-model-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    margin-top: 3vh;
}

#masterminds-close-wrapper {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
}

#masterminds-close-wrapper span {
    margin-right: 5px;
}

#masterminds-pop-up-selected-letter {
    background-color: white;
}

#masterminds-pop-up-box {
    height: 40%;
    width: 50%;
    background-color: white;
}

#masterminds-color-boxes-wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    height: 100%;
    background-color: white;
}

.pop-up-color-box {
    cursor: pointer;
}

#pop-up-red-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#pop-up-red-box {
    background-color: red;
    height: 90%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#pop-up-yellow-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#pop-up-yellow-box {
    background-color: yellow;
    height: 90%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#pop-up-green-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#pop-up-green-box {
    background-color: green;
    height: 90%;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#masterminds-clear-color {
    background-color: white;
    width: 100%;
    cursor: pointer;
}

/* Keyboard Styles */
#masterminds-keyboard-wrapper-wrapper {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: lightgrey;
    height: 100%;
}

#masterminds-keyboard-wrapper {
    justify-content: center;
    width: 100vw;
    padding-bottom: 2vh;
}

#masterminds-keyboard-wrapper > div > div{
    cursor: pointer;
}

#masterminds-keyboard-wrapper > .top-row-wrapper {
    display: grid;
    width: 98%;
    margin-left: 1vw;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
}

#masterminds-keyboard-wrapper > .top-row-wrapper > div {
    background-color: white;
    font-size: 2em;
    width: 33px;
}

#masterminds-keyboard-wrapper > .middle-row-wrapper {
    display: grid;
    width: 98%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    margin-left: 5px;
}

#masterminds-keyboard-wrapper > .middle-row-wrapper > div {
    background-color: white;
    font-size: 2em;
    width: 33px;
    justify-content: center;
}

#masterminds-keyboard-wrapper > .bottom-row-wrapper {
    display: grid;
    width: 98%;
    margin-left: 1vw;
    grid-template-columns: 3.2fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 3.6fr;
    grid-gap: 3px;
}

#masterminds-keyboard-wrapper > .bottom-row-wrapper > div {
    background-color: white;
    font-size: 2em;
    width: 100%;
    justify-content: center;
}


@media only screen and (min-width: 600px) {
    #masterminds-keyboard-wrapper-wrapper {
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    #masterminds-keyboard-wrapper {
        width: 450px;
    }
}
</style>