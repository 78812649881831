<template>
    <div id="masterminds-play-page-body-div">
        <!-- <div v-if="this.gameStatus == 'active'" id="masterminds-history-link" class="route" @click="handleRouteToHistory" style="margin-top: 1vh;">See Guess History</div> -->
        <!-- <div id="masterminds-game-play-page-output-text">{{ this.outputText }}</div> -->
        <div v-if="this.isLoading">Loading...</div>
        <div v-if="!this.yourTurn && !this.isLoading && this.gameStatus == 'active'">
            It's not your turn.  Wait for your opponent to guess.
        </div>

        <div v-if="this.yourTurn && !this.isLoading && this.gameStatus == 'active'">
            <div style="padding-top: 1vh; font-size: 1.14rem; font-weight: 900;">It's your turn!  Guess any word!</div>
            <h4>
                Target word has a max length of {{ this.gameRules.maxChars }} <br>
                <div v-if="this.gameRules.maxDups == 1">
                    Target word has all unique letters
                </div>
                <div v-else>
                    Target word can have to up to {{ this.gameRules.maxDups }} of each letter
                </div>
            </h4>
            <div id="masterminds-typing-box-wrapper">
                <div id="masterminds-typing-box">{{ this.currentGuessValue }}</div>
            </div>

        </div>

        <div v-if="this.gameStatus == 'pending' && this.activePlayerId !== this.myId && !this.isLoading">
            <h3>You were invited to play!  Accept to start!</h3>
            <h4>Your word can have a max length of {{ this.gameRules.maxChars }}</h4>
            <h4>Your word can have up to {{ this.gameRules.maxDups }} of each letter</h4>
            <input type="text" placeholder="Enter your word" v-model="gameStartWordCreate"/><br>
            <button style="margin-top: 3vh;" @click="handleAcceptGame">Start Game</button>
        </div>

        <div v-if="this.gameStatus == 'pending' && this.activePlayerId == this.myId && !this.isLoading">
            Waiting for opponent to accept
        </div>

        <div><MastermindsGuessHistory /></div>
    </div>
</template>

<script>
import router from '@/router';
import axios from 'axios';
import { store } from '@/stores/store.js';

import MastermindsGuessHistory from './MastermindsGuessHistory.vue'

const checkTurnTimeDelay = 10000;

export default {
    name: "MastermindsGamePlayPage",

    components: {
        MastermindsGuessHistory,
    },

    data() {
        return {
            isLoading: true,
            outputText: "",
            gameRules: {},
            yourTurn: false,
            currentGuessValue: "",
            activePlayerId: 0,
            myId: 0,
            gameStartWordCreate: "",
            checkTurnTimeout: 0,
            gameStatus: "",
        }
    },

    beforeMount() {
        if (store.token == "")
        {
            router.push("/");
        }
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
        this.myId = store.loginUser.ID;

        // Check who's turn it is
        axios.get("https://smada.com/api/mastermind/game/" + this.$route.params.id)
        .then(response => {
            this.isLoading = false;
            if(this.$route.params.status !== response.data.Status)
            {
                router.push("/masterminds");
            }
            this.isLoading = false;
            this.activePlayerId = response.data.Turn;
            this.gameRules = JSON.parse(response.data.Rules);
            this.gameStatus = response.data.Status;

            // Stop if the game is over
            if(this.gameStatus == 'done')
            {
                router.push("/masterminds");
            }

            if(response.data.Turn == store.loginUser.ID)
            {
                this.yourTurn = true;
            }
            else
            {
                this.checkTurnUpdate();
            }
        })
        .catch(error => {
            console.log("An error occured: ", error);
            if (error.response)
            {
                this.outputText = error.response.data;
            }
            else
            {
                this.outputText = "Something went wrong";
            }
        });
    },

    updated() {
        if(this.gameStatus == 'done')
        {
            this.outputText = "The game is over";
        }
        else if(this.$route.params.status !== this.gameStatus)
        {
            router.push("/masterminds");
        }
    },

    methods: {
        handleRouteToHistory() {
            router.push(this.$route.fullPath + "/history");
        },

        handleKeyPress(e) {
            // e.which = ascii value as if upper case (for some reason)
            // e.key = character value, Enter, or Backspace
            if(!this.yourTurn)
            {
                return
            }

            if(e.which >= 65 && e.which <= 90)
            {
                if(this.yourTurn)
                    this.handleLetterValues(e.key);
            }
            else if(e.which == 13)
            {
                this.handleSpecialKeyValues(e);
            }
            else if(e.which == 8)
            {
                this.handleSpecialKeyValues(e);
            }
        },

        handleLetterValues(value) {
            this.currentGuessValue += value.toUpperCase();
        },

        handleSpecialKeyValues(e) {
            let value;
            if(e.key)
            {
                value = e.key
            }
            else if(e.target)
            {
                value = e.target.id;
            }
            else
            {
                value = e;
            }
            if(value == 'Backspace')
            {
                this.currentGuessValue = this.currentGuessValue.slice(0, -1);
            }
            else if(value == 'Enter')
            {
                this.$parent.outputText = "";
                axios.post("https://smada.com/api/mastermind/game/" + this.$route.params.id, { MyWord:  this.currentGuessValue })
                .then(response => {
                    if(response.data.Result == undefined)
                    {
                        this.$parent.outputText = "You got the word!  You won!";
                        this.yourTurn = false;
                        this.currentGuessValue = "";
                        return
                    }
                    else
                    {
                        this.$parent.outputText = "Your result for " + this.currentGuessValue + " is " + response.data.Result;
                        this.passedTurns.push({Word: this.currentGuessValue, Result: response.data.Result});
                    }
                    this.currentGuessValue = "";
                    this.yourTurn = false;
                    this.checkTurnUpdate();
                })
                .catch(error => {
                    console.log("An error occured: ", error);
                    if (error.response)
                    {
                        this.outputText = error.response.data;
                    }
                    else
                    {
                        this.outputText = "Something went wrong";
                    }
                })
            }
        },

        handleAcceptGame() {
            axios.post("https://smada.com/api/mastermind/join/" + this.$route.params.id, { MyWord: this.gameStartWordCreate })
            .then(response => {
                router.push("/masterminds/game/" + response.data.ID + "/" + response.data.Status);
                router.go(0);
            })
            .catch(error => {
                console.log("An error occured: ", error);
                if (error.response)
                {
                    this.outputText = error.response.data;
                }
                else
                {
                    this.outputText = "Something went wrong";
                }
            })
        },

        checkTurnUpdate() {
            if(this.yourTurn)
            {
                return
            }
            else if(this.gameStatus == 'done')
            {
                router.push("/masterminds");
            }
            this.checkTurnTimeout =  setTimeout(() => {
                axios.get("https://smada.com/api/mastermind/game/" + this.$route.params.id)
                .then(response => {
                    if(response.data.Turn == store.loginUser.ID && response.data.Status == 'active')
                    {
                        this.$parent.newWordAdded = false;
                        this.yourTurn = true;
                        return
                    }
                    else if(response.data.Status == 'done')
                    {
                        this.outputText = "The game is over"
                        return
                    }
                })

                this.checkTurnUpdate();
            }, checkTurnTimeDelay);
        },
    },
}
</script>

<style scoped>
#masterminds-play-page-body-div {
    height: 92vh;
    width: 100vw;
    background-color: lightgrey;
    display: grid;
    grid-template-rows: 27% 73%;
}

#masterminds-game-play-page-output-text {
    margin-top: 2vh;
    color: red;
    height: 20px;
}

#masterminds-typing-box-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
}

#masterminds-typing-box {
    height: 40px;
    width: 300px;
    font-size: 1.5em;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>