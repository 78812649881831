<template>
    <div>
        <div id="assassins-title">WELCOME TO <br><span><i>Assassins</i></span></div>
        <span>A friend building game of stealth and paranoia</span>
        <h4>Current Games:</h4>
        <div v-if="this.isLoading">Loading...</div>
        <div id="games-list" v-for="(game) in gamesResponseData" :key="game.id">
            <div class="game-wrapper">
                <div id="game-name" class="game-element">{{ game.name }}</div>
                <div id="game-player-count" class="game-element">{{ game.player_count }} players</div>
                <div v-if="game.status == 'active' && game.in_game !== 0" to="/assassins/game-info" id="game-status" class="game-element route" @click="routeToInfoPage(game)">{{ game.status }}</div>
                <div v-else-if="game.status == 'pending' && game.in_game == 0" id="game-status" class="game-element link" @click="JoinClick(game.id)">Join</div>
                <div v-else-if="game.status == 'pending' && game.in_game !== 0" to="assassins/game-info" id="game-status" class="game-element link" @click="routeToInfoPage(game)">Added</div>
            </div>
        </div>
        <RouterLink class="route" to="assassins/create-game" v-if="isAnAdmin">Create new game</RouterLink>
    </div>
</template>

<script>
import axios from "axios";
import router from "src/router/index.js";
import { store } from '@/stores/store';

export default {
    name: 'AssassinsGameHome',

    data() {
        return {
            gamesResponseData: [],
            isAnAdmin: false,
            isLoading: true,
        }
    },

    beforeMount() {
        axios.defaults.headers.common['Authorization'] = `Bearer ` + store.token;
        if (store.token == "")
        {
            router.push('/');
        } else {
            axios.get("https://smada.com/api/assassins/game/list")
            .then(response => {
                this.gamesResponseData = response.data;
                this.isLoading = false;
            })
            .catch(error => {
                console.log("An error occured: " + error);
            });
        }
    },

    mounted() {
        if(store.loginUser.Role == 'admin')
        {
            this.isAnAdmin = true;
        }
    },


    methods: {
        JoinClick(id) {
            axios.get("https://smada.com/api/assassins/game/" + id + "/join")
            .then(() => {
                this.$router.go(0);
            })
            .catch(error => {
                console.log("An error occured: " + error);
            })

        },

        routeToInfoPage(game) {
            router.push("assassins/game-info/" + game.id + "/" + game.status);
        }
    }
}

</script>

<style>
#assassins-title {
    font-weight: 900;
}

#assassins-title span{
    color: red;
    font-size: 3em;
    font-family: 'Caveat';
}

#games-list {
    display: flex;
    justify-content: center;
}

.game-wrapper {
    display: grid;
    height: 6vh;
    grid-template-columns: 3.4fr 1.6fr 1.6fr;
    width: 95vw;
    margin-bottom: 12px;
}

.game-element {
    font-size: 0.85rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

#game-name {
    background-color: grey;
}

#game-player-count {
    background-color: lightgrey;
}

#game-status {
    background-color: grey;
}
</style>