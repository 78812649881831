<template>
    <div id="page-header" v-if='playerName !== ""'>
        <div v-if="this.$route.fullPath !== '/home'">
            <span id="back-to-home-link" class="link" @click="backToHomeClick">Home</span>
            <span id="back-link" class="link" @click="handleBackClick()">Back</span>
        </div>
        <div id="header-name-wrapper">{{ playerName }}</div>
        <div id="log-out-link" class="link" @click="logOutClick">
            Logout <font-awesome-icon icon="fa-solid fa-right-from-bracket" />
        </div>
    </div>
</template>

<script>
import { store } from '../stores/store';
import router from "src/router/index.js"
export default {
    name: 'HeaderCode',

    data() {
        return {
            playerName: "",
        }
    },

    watch: {
        '$route':function(newUrl, oldUrl) {
            if (oldUrl.fullPath !== newUrl.fullPath)
            {
                if(this.$route.fullPath == "/" || this.$route.fullPath == "/register")
                {
                    this.playerName = "";
                    return;
                }
                if(this.playerName == "")
                {
                    this.playerName = JSON.parse(localStorage.getItem("user_data")).me.Name;
                }
            }
        }
    },

    methods: {
        logOutClick() {
            store.loginUser = {};
            store.token = "";
            store.tokenExpires = 0;
            store.name = "";
            localStorage.removeItem("user_data");
            router.push('/');
        },

        backToHomeClick() {
            router.push('/home');
        },

        handleBackClick() {
            router.back();
        }
    }
}
</script>

<style scoped>
#page-header {
    background-color: lightblue;
    height: 8vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

#header-name-wrapper {
    position: absolute;
}

#log-out-link {
    align-items: center;
    margin-left: auto;
    margin-right: 2vw;
}

#back-link {
    margin-right: auto;
    margin-left: 4vw;
}

#back-to-home-link {
    margin-right: auto;
    margin-left: 2vw;
}
</style>