<template>
    <h2>Create an account to play!</h2>
    <div id="create-account-output-text">{{ outputText }}</div>
    <form id="register-login-form">
        <div class="input-field-wrapper" id="register-email-wrapper">
            <input id="email-textbox" type="text" placeholder="Enter your email" v-model="email"/>
        </div>

        <div class="input-field-wrapper" id="register-name-wrapper">
            <input id="name-textbox" type="text" placeholder="Enter your first & last name" v-model="name"/>
        </div>

        <div class="input-field-wrapper" id="register-password-wrapper">
            <input type="password" class="password-textbox" placeholder="Create your password" v-model="password"/>
        </div>
        <div class="input-field-wrapper" id="register-password-retype-wrapper">
            <input type="password" class="password-textbox" placeholder="Re-enter your password" v-model="passwordCheck"/>
        </div>
        <div class="submit-button-wrapper">
            <button type="button" class="submit-button" @click="createAccountClick">Create Account</button>
        </div>
  </form>
  <RouterLink class="route" to="/">Go back to sign in</RouterLink>
</template>

<script>
import axios from "axios";
import router from "../router/index.js";
import { store } from '../stores/store.js';
export default {
    name: 'RegisterPage',

    data() {
        return {
            email: "",
            name: "",
            password: "",
            passwordCheck: "",
            outputText: "",
        }
    },

    watch: {
        outputText(newValue) {
            if(newValue !== "")
                {
                    this.outputTextTimeout = setTimeout(() => {
                        this.outputText = "";
                    }, 3000)
                }
        }
    },

    methods: {
        createAccountClick(e) {
            e.preventDefault();
            if (this.password != this.passwordCheck)
            {
                this.outputText = "Your passwords didn't match";
                return
            }
            else if (this.email == "" || this.name == "" || this.password == "")
            {
                this.outputText = "Please fill out all fields";
                return;
            }
            else
            {
                if (!this.email.includes('@'))
                {
                    this.outputText = "Please enter a valid email";
                    return;
                }
            }
            axios.post("https://smada.com/api/public/register",
            {
                email: this.email,
                password: this.password,
                name: this.name,
            })
            .then(response => {
                if(response.data.token)
                {
                    localStorage.setItem("user_data", JSON.stringify(response.data));

                    store.token = response.data.token;
                    store.loginUser = response.data.me;
                    store.tokenExpires = response.data.expires;
                    store.name = response.data.me.Name;
                    router.push('/home');
                }
            })
            .catch(error => {
                console.log("An error occured: " + error);

                if(error.response.data.message)
                    this.outputText = error.response.data.message;
                else
                    this.outputText = "Something went wrong";
            })
        }
    }
}
</script>

<style scoped>
#register-login-form {
    display: grid;
    grid-template-rows: 2.5fr 2.5fr 2.5fr 2.5fr 1fr 0.5fr;
}

#create-account-output-text {
    color: red;
    height: 20px;
}

</style>