import { reactive } from 'vue'

let userData = JSON.parse(localStorage.getItem("user_data"));

let lsUser = {}
let lsToken = ""
let lsName = ""
let lsExpire = 0

if (userData) {
    lsUser = userData.me;
    lsToken = userData.token;
    lsName = userData.me.Name;
    lsExpire = userData.expires;
}

export const store = reactive({
    loginUser: lsUser,
    token: lsToken,
    tokenExpires: lsExpire,
    name: lsName,
})